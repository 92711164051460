<!-- start page title -->
<app-pagetitle title="Team" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row mb-3">
    <div class="col-sm-6">
        <div class="d-flex align-items-center justify-content-sm-start justify-content-start">
            <div class="fs-1 text-primary me-2"><i class="mdi mdi-account-supervisor-circle"></i></div>
            <div>
                <h4 class="mb-0 fs-6">Team Members</h4>
            </div>
        </div>
    </div> <!--end col -->
    <div class="col-sm-6">
        <div class="d-flex align-items-center justify-content-sm-end justify-content-start">
            <div class="me-4">
                <a class="fs-2 me-2" href="javascript:void(0);"><i class="mdi mdi-dots-grid"></i></a>
                <a class="fs-2 text-muted" href="javascript:void(0);"><i class="mdi mdi-menu"></i></a>
            </div>
            <div class="btn-group p-1 rounded-5 team-btn shadow-sm" role="group" aria-label="Basic radio toggle button group">
                <div>
                    <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked>
                    <label class="btn btn-outline-primary rounded-5 mb-0 border-0 px-4" for="btnradio1">All</label>
                </div>
                <div>
                    <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off">
                    <label class="btn btn-outline-primary rounded-5 mb-0 border-0" for="btnradio3">My Team</label>
                </div>
            </div>
        </div>
    </div> <!--end col -->
</div> <!--end row -->

<div class="row">
    @for(data of TeamDatas;track $index){
    <div class="col-xl-3">
        <div class="card">
            <div class="card-body">
                <div>
                    <div>
                        <div class="float-start fs-3 text-danger">
                            <i class="mdi mdi-cards-heart"></i>
                        </div>
                        <div class="dropdown float-end" ngbDropdown >
                            <a class="dropdown-toggle fs-3 text-muted" href="javascript:void(0);" role="button" data-bs-toggle="dropdown"
                                aria-haspopup="true" ngbDropdownToggle>
                                <i class="mdi mdi-dots-horizontal"></i>
                            </a>
                    
                            <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu >
                                <a class="dropdown-item" href="javascript:void(0);">Edit</a>
                                <a class="dropdown-item" href="javascript:void(0);">Action</a>
                                <a class="dropdown-item" href="javascript:void(0);">Remove</a>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="text-center bg-pattern">
                        <img src="{{data.profile}}" alt="avatar-4" class="avatar-lg rounded-circle mb-3">
                        <h5 class="text-primary mb-2">{{data.name}}</h5>
                        <h6 class="text-muted font-size-13 mb-0">{{data.email}}</h6>
                    </div>
                    <hr class="text-muted my-3">
                    <div class="text-center team-social">
                        <a class="fs-4 text-muted me-2" href="javascript:void(0);" role="button">
                            <i class="mdi mdi-basketball"></i>
                        </a>
                        <a class="fs-4 text-muted me-2" href="javascript:void(0);" role="button">
                            <i class="mdi mdi-facebook"></i>
                        </a>
                        <a class="fs-4 text-muted me-2" href="javascript:void(0);" role="button">
                            <i class="mdi mdi-twitter"></i>
                        </a>
                        <a class="fs-4 text-muted" href="javascript:void(0);" role="button">
                            <i class="mdi mdi-email"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div><!--end col -->
}
</div><!--end row -->

<div class="row align-items-center">
    <div class="col-sm-6">
        <div class="text-center text-sm-start">
            <p class="mb-sm-0">Showing {{service.startIndex}} to {{service.endIndex}} of {{service.totalRecords}} entries</p>
        </div>
    </div> <!--end col -->
    <div class="col-sm-6">
        <div class="page-box">
            <nav aria-label="Page navigation example">
                <ngb-pagination class="pagination justify-content-center justify-content-sm-end" [collectionSize]="(total | async)!" [(page)]="service.page" [pageSize]="service.pageSize"></ngb-pagination>
            </nav>
        </div>
    </div> <!--end col -->
</div> <!--end row -->