<div class="topnav">
    <div class="container-fluid">
        <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
            <div class="collapse navbar-collapse active" id="topnav-menu-content">
                <ul class="navbar-nav">
                    @for(item of menuItems;track $index){
                    <li class="nav-item dropdown">
                        @if(item.subItems){
                        <a class="nav-link dropdown-toggle arrow-none" (click)="onMenuClick($event)" href="javascript: void(0);" id="topnav-components" role="button">
                            <i class="bx {{item.icon}} me-1"></i>
                            {{item.label | translate }}
                            <div class="arrow-down"></div>
                        </a>
                        @if(hasItems(item)){
                        <div class="dropdown-menu" aria-labelledby="topnav-dashboard">
                            @for(subitem of item.subItems;track $index){
                            @if(hasItems(subitem)){
                            <div class="dropdown">
                                <a class="dropdown-item dropdown-toggle" href="javascript: void(0);" (click)="onMenuClick($event)">{{ subitem.label | translate }}
                                    <div class="arrow-down"></div>
                                </a>
                                <div class="dropdown-menu">
                                    @for(subSubitem of subitem.subItems;track $index){
                                    @if(hasItems(subSubitem)){
                                    <div class="dropdown">

                                        <a class="dropdown-item dropdown-toggle" href="javascript: void(0);" (click)="onMenuClick($event)">{{ subSubitem.label | translate }}
                                            <div class="arrow-down"></div>
                                        </a>
                                        <div class="dropdown-menu">
                                            @for(subSubSubitem of subSubitem.subItems;track $index){
                                            <a class="dropdown-item side-nav-link-ref" href="javascript: void(0);" [routerLink]="subSubSubitem.link" routerLinkActive="active">{{ subSubSubitem.label |
                                                translate }}</a>
                                            }
                                        </div>
                                    </div>
                                    }@else {
                                    <a class="dropdown-item side-nav-link-ref" href="javascript: void(0);" [routerLink]="subSubitem.link" routerLinkActive="active">{{ subSubitem.label | translate }}</a>
                                    }
                                    }
                                </div>
                            </div>
                            }@else{
                            <a class="col dropdown-item side-nav-link-ref" href="javascript: void(0);" [routerLink]="subitem.link" routerLinkActive="active">{{subitem.label | translate }}</a>
                            }
                            }
                        </div>
                        }
                        }@else {
                        <a class="nav-link dropdown-toggle arrow-none" (click)="onMenuClick($event)" href="javascript: void(0);" id="topnav-components" [routerLink]="item.link" role="button">
                            <i class="bx {{item.icon}} me-2"></i>{{ item.label | translate}}
                        </a>
                        }

                    </li>
                    }
                </ul>
            </div>
        </nav>
    </div>
</div>