const teamData = [
    {
      id:1,
      profile: 'assets/images/users/avatar-1.jpg',
      name: 'Peter Johne',
      email: 'peterjohne@gmail.com',
      status: true,
    },
    {
        id:2,
        profile: 'assets/images/users/avatar-2.jpg',
        name: 'Allyson Booker',
        email: 'peterjohne@gmail.com',
        status: true,
    },
    {
      id:3,
      profile: 'assets/images/users/avatar-3.jpg',
      name: 'Nikolas Cablela',
      email: 'nikolascablela@gmail.com',
      status: true,
    },
    {
      id:4,
      profile: 'assets/images/users/avatar-4.jpg',
      name: 'Kira Collier',
      email: 'kiracollier@gmail.com',
      status: true,
    },
    {
      id:5,
      profile: 'assets/images/users/avatar-5.jpg',
      name: 'Jellyn Ferral',
      email: 'jellynferral@gmail.com',
      status: true,
    },
    {
      id:6,
      profile: 'assets/images/users/avatar-6.jpg',
      name: 'Damon Boxtar',
      email: 'damonboxtar@gmail.com',
      status: true,
    },
    {
      id:7,
      profile: 'assets/images/users/avatar-7.jpg',
      name: 'Ross Jordan',
      email: 'rossjordan@gmail.com',
      status: true,
    },
    {
      id:8,
      profile: 'assets/images/users/avatar-8.jpg',
      name: 'Monika Daker',
      email: 'monikadaker@gmail.com',
      status: true,
    },
    {
      id:9,
      profile: 'assets/images/users/avatar-1.jpg',
      name: 'Peter Johne',
      email: 'peterjohne@gmail.com',
      status: true,
    },
    {
      id:10,
      profile: 'assets/images/users/avatar-7.jpg',
      name: 'Ross Jordan',
      email: 'rossjordan@gmail.com',
      status: true,
    },
    {
      id:11,
      profile: 'assets/images/users/avatar-5.jpg',
      name: 'Jellyn Ferral',
      email: 'jellynferral@gmail.com',
      status: true,
    },
    {
      id:12,
      profile: 'assets/images/users/avatar-8.jpg',
      name: 'Monika Daker',
      email: 'monikadaker@gmail.com',
      status: true,
    },
  ];
  
  export { teamData };
  