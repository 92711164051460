<!-- start page title -->
<app-pagetitle title="Chat" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->


<div class="d-lg-flex mb-4">
  <div class="chat-leftsidebar card">
    <div class="p-3 px-4 bg-light">
      <div class="d-flex align-items-start">
        <div class="flex-shrink-0 align-self-center me-3">
          <img src="assets/images/users/avatar-4.jpg" class="avatar-xs rounded-circle" alt="avatar" />
        </div>
        <div class="flex-grow-1">
          <h5 class="font-size-16 mb-1">
            <a href="javascript:void(0)" class="text-reset">Marcus
              <i class="mdi mdi-circle text-success align-middle font-size-10 ms-1"></i></a>
          </h5>
          <p class="text-muted mb-0 font-size-12">Available</p>
        </div>

        <div>
          <div class="dropdown chat-noti-dropdown" ngbDropdown>
            <button class="btn dropdown-toggle py-0" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
              <i class="mdi mdi-dots-horizontal"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
              <a class="dropdown-item" href="javascript:void(0);">Profile</a>
              <a class="dropdown-item" href="javascript:void(0);">Edit</a>
              <a class="dropdown-item" href="javascript:void(0);">Add Contact</a>
              <a class="dropdown-item" href="javascript:void(0);">Settings</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="p-3">
      <div class="search-box chat-search-box">
        <div class="position-relative">
          <input type="text" class="form-control border-light rounded" placeholder="Search..." (keyup)="ContactSearch()" id="searchContact" />
          <i class="mdi mdi-magnify search-icon"></i>
        </div>
      </div>
    </div>

    <div>
      <div class="border-top">
        <ngx-simplebar class="chat-message-list p-4">
          <div>
            <h5 class="font-size-13 text-uppercase mb-3">
              <i class="mdi mdi-account-outline me-1"></i> Contacts
            </h5>
            <ul class="list-unstyled chat-list mb-0 chat-user-list">
              @for(data of chatData;track $index){
              <li class="">
                <a href="javascript: void(0);" (click)="chatUsername($event,data.id,data.name, data.image)">
                  <div class="d-flex align-items-start">
                    <div class="flex-shrink-0 align-self-center me-3">
                      <div class="user-img online">
                        @if(data.image){
                        <img src="{{ data.image }}" class="rounded-circle avatar-xs" alt="avatar" />
                        <span class="user-status"></span>
                        }@else {
                        <div class="avatar-xs align-self-center">
                          <span class="avatar-title rounded-circle bg-primary">
                            {{ data.name.charAt(0) }}
                          </span>
                        </div>
                        }
                      </div>

                    </div>

                    <div class="flex-grow-1 overflow-hidden">
                      <h5 class="text-truncate font-size-14 mb-1">
                        {{ data.name }}
                      </h5>
                      <p class="text-truncate font-size-13 mb-0">
                        {{ data.message }}
                      </p>
                    </div>
                    <div class="font-size-11">{{ data.time }}</div>
                  </div>
                </a>
              </li>
              }
            </ul>
          </div>
        </ngx-simplebar>
      </div>
    </div>
  </div>
  <!-- end chat-leftsidebar -->

  <div _ngcontent-bon-c125="" class="chat-welcome-section w-100 mt-4 mt-sm-0 ms-lg-1 card">
    <div _ngcontent-bon-c125="" class="row w-100 justify-content-center">
      <div _ngcontent-bon-c125="" class="col-xxl-5 col-md-7">
        <div _ngcontent-bon-c125="" class="p-4 text-center">
          <div _ngcontent-bon-c125="" class="avatar-xl mx-auto mb-4">
            <div _ngcontent-bon-c125="" class="avatar-title bg-light rounded-circle icon-demo-content">
              <i class="mdi mdi-wechat fs-1 me-0"></i>
            </div>
          </div>
          <h6 _ngcontent-bon-c125="" class="d-inline px-3 py-2 rounded-pill bg-light text-reset"> Start Conversation </h6>
        </div>
      </div>
    </div>
  </div>

  <div class="w-100 user-chat mt-4 mt-sm-0 ms-lg-1 d-none">
    <div class="card">
      <div class="p-3 px-lg-4 border-bottom">
        <div class="row">
          <div class="col-md-4 col-6">
            <div class="d-flex align-items-center">
              <div class="d-block d-lg-none me-2 ms-0">
                <a href="javascript: void(0);" class="user-chat-remove text-muted font-size-16 p-2"><i class="ri-arrow-left-s-line"></i></a>
              </div>
              <div class="flex-shrink-0 me-3 ms-0">
                @if(profile){
                <img src="{{profile}}" class="rounded-circle avatar-xs" alt="avatar" />
                }@else {
                <div class="avatar-xs align-self-center">
                  <span class="avatar-title rounded-circle bg-primary">
                    {{ username.charAt(0) }}
                  </span>
                </div>
                }
              </div>
              <div class="flex-grow-1 overflow-hidden">
                <h5 class="font-size-16 mb-0 text-truncate">
                  <a href="javascript:void(0)" class="text-reset user-profile-show">{{username}}</a>
                  <i class="mdi mdi-circle text-success align-middle font-size-10 ms-1"></i>
                </h5>
              </div>
            </div>
          </div>
          <div class="col-md-8 col-6">
            <ul class="list-inline user-chat-nav text-end mb-0">
              <li class="list-inline-item">
                <div class="dropdown" ngbDropdown>
                  <button class="btn nav-btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                    <i class="mdi mdi-magnify"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-end dropdown-menu-md p-0" ngbDropdownMenu>
                    <form class="p-2">
                      <div>
                        <input type="text" class="form-control border-light rounded" placeholder="Search..." (keyup)="MessageSearch()" id="searchMessage" />
                      </div>
                    </form>
                  </div>
                </div>
              </li>

              <li class="list-inline-item">
                <div class="dropdown" ngbDropdown>
                  <button class="btn nav-btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                    <i class="mdi mdi-dots-horizontal"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                    <a class="dropdown-item" href="javascript:void(0);">Profile</a>
                    <a class="dropdown-item" href="javascript:void(0);">Archive</a>
                    <a class="dropdown-item" href="javascript:void(0);">Muted</a>
                    <a class="dropdown-item" href="javascript:void(0);" (click)="deleteAllMessage($event)">Delete</a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="px-lg-2 position-relative">
        <ngx-simplebar class="chat-conversation p-3" #scrollRef>
          <ul class="list-unstyled mb-0" id="users-conversation">
            <li class="chat-day-title">
              <div class="title">Today</div>
            </li>
            @for(data of message;track $index){
            <li [ngClass]="{ 'right': data.align === 'right' }">
              <div class="conversation-list d-flex" [ngClass]="{ 'order-3': data.align === 'right' }">
                <div class="chat-user-img online align-self-start" [ngClass]="{ 'order-3': data.align === 'right' }">
                  @if(data.align == 'right'){
                  <div class="">
                    <img :src="assets/images/users/avatar-4.jpg" class="rounded-circle avatar-xs" alt="avatar" />
                  </div>
                  }@else {
                  <div>
                    @if(profile){
                    <img src="{{profile}}" class="rounded-circle avatar-xs" alt="avatar" />
                    }@else {
                    <div class="avatar-xs align-self-center">
                      <span class="avatar-title rounded-circle bg-primary"> {{username.charAt(0)}} </span>
                    </div>
                    }
                  </div>
                  }


                </div>
                <div class="ctext-wrap">
                  <div class="ctext-wrap-content">
                    @if(data.replayName){
                    <div class="replymessage-block mb-0 d-flex align-items-start">
                      <div class="flex-grow-1">
                        <h5 class="conversation-name">{{data.replayName}}</h5>
                        <p class="mb-0">{{data.replaymsg}}</p>
                      </div>
                      <div class="flex-shrink-0">
                        <button type="button" class="btn btn-sm btn-link mt-n2 me-n3 font-size-18">
                        </button>
                      </div>
                    </div>
                    }
                    <p class="mb-0">
                      <span class="ctext-content">{{ data.message }}</span>
                      <span class="d-inline-block font-size-12 text-muted ms-3">{{ data.time }}</span>
                    </p>
                  </div>
                  <div class="dropdown align-self-start" ngbDropdown>
                    <a class="dropdown-toggle" href="javascript:void(0);" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                      <i class="mdi mdi-dots-vertical"></i>
                    </a>
                    <div class="dropdown-menu" ngbDropdownMenu>
                      <a class="dropdown-item" href="javascript:void(0);" (click)="copyMessage($event)">Copy</a>
                      <a class="dropdown-item" href="javascript:void(0);" (click)="replyMessage($event,data.align)">Reply</a>
                      <a class="dropdown-item" href="javascript:void(0);">Forward</a>
                      <a class="dropdown-item" href="javascript:void(0);" (click)="deleteMessage($event)">Delete</a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            }
          </ul>
        </ngx-simplebar>
        <div class="alert alert-warning alert-dismissible copyclipboard-alert px-4 fade show" id="copyClipBoard" role="alert">
          Message copied
        </div>
      </div>

      <form (ngSubmit)="messageSave()" [formGroup]="formData" class="row">
        <div class="p-3 chat-input-section">
          <div class="row">

            <div class="col">
              <div class="position-relative">
                <input formControlName="message" [ngClass]="{'is-invalid': chatSubmit && form.message.errors}" type="text" class="form-control chat-input rounded" placeholder="Enter Message..." [(ngModel)]="emoji" (blur)="onBlur()" (focus)="onFocus()" />
                @if(chatSubmit && form.message.errors){
                <div class="invalid-feedback">
                  @if(form.message.errors.required){
                  <span>This value is required.</span>
                  }
                </div>
                }
              </div>
            </div>
            <div class="col-auto">
              <button type="submit" class="btn btn-primary chat-send w-md waves-effect waves-light">
                <span class="d-none d-sm-inline-block me-2">Send</span>
                <i class="mdi mdi-send float-end"></i>
              </button>
            </div>
          </div>
        </div>
      </form>

      <div class="replyCard">
        <div class="card mb-0">
          <div class="card-body py-3 bg-light">
            <div class="replymessage-block mb-0 d-flex align-items-start">
              <div class="flex-grow-1">
                <h5 class="conversation-name"></h5>
                <p class="text-muted mb-0 font-size-12"></p>
              </div>
              <div class="flex-shrink-0">
                <button type="button" id="close_toggle" class="btn btn-sm btn-link mt-n2 me-n3 fs-18 shadow-none" (click)="closeReplay()">
                  <i class="bx bx-x align-middle"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>