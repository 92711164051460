const chatData = [
    {
        id:1,
        image: 'assets/images/users/avatar-2.jpg',
        name: 'Steven Franklin',
        message: 'Hey! there I\'m available',
        time: '05 min',
        color: 'secondary',
        messages: [
            {
                id:1,
                name: 'Steven Franklin',
                message: 'Hello!',
                time: '10:00',
                profile: 'assets/images/users/avatar-2.jpg'
            },
            {
                id:2,
                align: 'right',
                name: 'Henry Wells',
                message: 'Hi, How are you? What about our next meeting?',
                time: '10:02',
                profile: 'assets/images/users/avatar-4.jpg'
            },
            {
                id:3,
                name: 'Steven Franklin',
                message: 'Yeah everything is fine',
                time: '10:06',
                profile: 'assets/images/users/avatar-2.jpg'
            },
            {
                id:4,
                name: 'Steven Franklin',
                message: '& Next meeting tomorrow 10.00AM',
                time: '10:06',
                profile: 'assets/images/users/avatar-2.jpg'
            },
            {
                id:5,
                align: 'right',
                name: 'Henry Wells',
                message: 'Wow that\'s great',
                time: '10:07',
                profile: 'assets/images/users/avatar-4.jpg'
            },
            {
                id:6,
                align: 'right',
                replayName: "You",
                replaymsg: `Wow that's great`,
                message: 'Thanks',
                time: '09:08 am',
                profile: 'assets/images/users/avatar-4.jpg'
            },
        ]
    },
    {
        id:2,
        image: 'assets/images/users/avatar-3.jpg',
        name: 'Adam Miller',
        message: 'I\'ve finished it! See you so',
        time: '12 min',
        color: 'success',
        messages: [
            {
                id:1,
                name: 'Steven Franklin',
                message: 'Hello!',
                time: '10:00',
                profile: 'assets/images/users/avatar-2.jpg'
            },
            {
                id:2,
                align: 'right',
                name: 'Henry Wells',
                message: 'Hi, How are you? What about our next meeting?',
                time: '10:02',
                profile: 'assets/images/users/avatar-4.jpg'
            },
        ]
    },
    {
        id:3,
        name: 'Keith Gonzales',
        message: 'This theme is awesome!',
        time: '24 min',
        color: 'success',
        messages: [
            {
                id:1,
                name: 'Steven Franklin',
                message: 'Hello!',
                time: '10:00',
                profile: 'assets/images/users/avatar-2.jpg'
            },
            {
                id:2,
                align: 'right',
                name: 'Henry Wells',
                message: 'Wow that\'s great',
                time: '10:07',
                profile: 'assets/images/users/avatar-4.jpg'
            },
        ]
    },
    {
        id:4,
        image: 'assets/images/users/avatar-4.jpg',
        name: 'Jose Vickery',
        message: 'Nice to meet you',
        time: '1 hr',
        color: 'warning',
        messages: [
            {
                id:1,
                name: 'Steven Franklin',
                message: 'Hello!',
                time: '10:00',
                profile: 'assets/images/users/avatar-2.jpg'
            },
            {
                id:2,
                align: 'right',
                name: 'Henry Wells',
                message: 'Hi, How are you? What about our next meeting?',
                time: '10:02',
                profile: 'assets/images/users/avatar-4.jpg'
            },
        ]
    },
    {
        id:5,
        name: 'Mitchel Givens',
        message: 'Hey! there I\'m available',
        time: '3 hrs',
        color: 'secondary',
        messages: [
            {
                id:1,
                name: 'Steven Franklin',
                message: 'Hello!',
                time: '10:00',
                profile: 'assets/images/users/avatar-2.jpg'
            },
            {
                id:2,
                align: 'right',
                name: 'Henry Wells',
                message: 'Hi, How are you? What about our next meeting?',
                time: '10:02',
                profile: 'assets/images/users/avatar-4.jpg'
            },
        ]
    },
    {
        id:6,
        image: 'assets/images/users/avatar-4.jpg',
        name: 'Stephen Hadley',
        message: 'I\'ve finished it! See you so',
        time: '5 hrs',
        color: 'success',
        messages: [
            {
                id:1,
                name: 'Steven Franklin',
                message: 'Hello!',
                time: '10:00',
                profile: 'assets/images/users/avatar-2.jpg'
            },
            {
                id:2,
                align: 'right',
                name: 'Henry Wells',
                message: 'Hi, How are you? What about our next meeting?',
                time: '10:02',
                profile: 'assets/images/users/avatar-4.jpg'
            },
        ]
    },
    {
        id:7,
        image: 'assets/images/users/avatar-2.jpg',
        name: 'Keith Gonzales',
        message: 'This theme is awesome!',
        time: '24 min',
        color: 'success',
        messages: [
            {
                id:1,
                name: 'Steven Franklin',
                message: 'Hello!',
                time: '10:00',
                profile: 'assets/images/users/avatar-2.jpg'
            },
            {
                id:2,
                align: 'right',
                name: 'Henry Wells',
                message: 'Hi, How are you? What about our next meeting?',
                time: '10:02',
                profile: 'assets/images/users/avatar-4.jpg'
            },
        ]
    },
];

export { chatData };
